"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeLogo = exports.changeHeaderBackground = exports.changeHeaderProperties = void 0;
/**
 * Aggiunge o rimuove classi css a "header"
 * a seconda se addBackground è true o false
 * (di default è true)
 *
 * @param addBackground boolean
 */
function changeHeaderProperties(addBackground = true) {
    window.requestAnimationFrame((_ev) => {
        changeHeaderBackground(addBackground);
        const logoEl = document.querySelector('#logo');
        if (logoEl) {
            if (logoEl.className.includes('initial_logo_white')) {
                changeLogo(addBackground);
            }
        }
    });
}
exports.changeHeaderProperties = changeHeaderProperties;
function changeHeaderBackground(addBackground) {
    // si ricava l'elemento header
    const headerEl = document.querySelector('header');
    if (addBackground) {
        // si aggiungono le classi
        if (headerEl && !headerEl.className.includes('header_white')) {
            headerEl.className += ' header_white';
        }
    }
    else {
        // si rimuovono le classi
        if (headerEl && headerEl.className.includes('header_white')) {
            headerEl.className = headerEl.className.replace('header_white', '');
        }
    }
}
exports.changeHeaderBackground = changeHeaderBackground;
function changeLogo(toBlack = true) {
    // si ricava l'elemento img logo
    const logoEl = document.querySelector('#logo');
    if (logoEl) {
        const logoImg = logoEl;
        if (toBlack) {
            if (logoImg.src.includes('logo-white')) {
                logoEl.src = logoEl.src.replace('logo-white', 'logo');
            }
        }
        else {
            if (!logoImg.src.includes('logo-white')) {
                logoEl.src = logoEl.src.replace('logo', 'logo-white');
            }
        }
    }
}
exports.changeLogo = changeLogo;
