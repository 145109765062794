"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleMenuAnimation = exports.enableThirdLevelMenu = void 0;
const header_1 = require("./header");
const main_1 = require("./main");
/**
 * abilita il menù di terzo livello
 * aggiungendo o togliendo la classe show agli ul nascosti di terzo livello
 */
function enableThirdLevelMenu() {
    const items = document.querySelectorAll('ul.dropdown-menu .dropdown-toggle');
    // per ogni ul.dropdown-menu .dropdown-toggle
    // si aggiunge un event listener per il click
    items.forEach((item) => {
        item.addEventListener('click', (event) => {
            // al click si disabilita il comportamento di defualt
            event.preventDefault();
            event.stopPropagation();
            closePreviousNestedMenu();
            // si aggiunge la classe show all'ul subito accanto
            const nextUl = event.currentTarget.nextSibling;
            nextUl.className += ' show';
        });
    });
    const mainButtons = document.querySelectorAll('.nav-link.dropdown-toggle');
    mainButtons.forEach((item) => {
        item.addEventListener('click', (event) => {
            closePreviousNestedMenu();
        });
    });
}
exports.enableThirdLevelMenu = enableThirdLevelMenu;
/**
 * chiude gli item di terzo livello
 */
function closePreviousNestedMenu() {
    // si cercano dropdown-menu.lev-3
    const s = [...document.querySelectorAll('.dropdown-menu.lev-3')];
    const oldShows = s.filter(el => el.classList.contains('show'));
    // se prima avevano la classe show, si rimuove
    oldShows === null || oldShows === void 0 ? void 0 : oldShows.forEach(el => {
        el.className = el.className.replace('show', '');
    });
}
/**
 * Aggiunge/rimuove classe "nav-open" all'hamburger menu per avviare animazione;
 * cambia colori logo e hamburger menu e sfondo menu
 */
function toggleMenuAnimation() {
    var _a;
    (_a = document.querySelector('.nav-toggle')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function (e) {
        const btn = e.currentTarget;
        if (!isMobileMenuOpened()) {
            btn.className += ' nav-open';
            (0, header_1.changeHeaderBackground)(true);
            (0, header_1.changeLogo)(true);
        }
        else {
            btn.className = btn.className.replace(' nav-open', '');
            (0, main_1.doOnScroll)();
        }
    });
}
exports.toggleMenuAnimation = toggleMenuAnimation;
/**
 * Restituisce true se il menu mobile è aperto
 *
 * @returns boolean
 */
function isMobileMenuOpened() {
    const btn = document.querySelector('.nav-toggle');
    return btn.className.includes('nav-open');
}
