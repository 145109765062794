"use strict";
// import { gsap } from "gsap";
// import $ from "jquery";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doOnScroll = void 0;
const sliders_1 = require("./sliders");
const menu_1 = require("./menu");
const header_1 = require("./header");
/**
 * globalVariables variabili globali utilizzate all'interno del sito
 */
let globalVariables = {
    lastKnownScrollPosition: 0,
    headerShadowTheshold: 50,
    faqDataTargetPrefix: '#collapse-',
    // TODO qui possono andarci altre proprietà utili
    // dopo averle prima definite nell'interfaccia `GlobalVariables` in models.ts
};
// al caricamento completo della pagina si chiama
// la funzione onDocumentLoaded
document.addEventListener('DOMContentLoaded', function () {
    onDocumentLoaded();
});
// $(() => {
// 	onDocumentLoaded();
// });
/**
 * chiamata quando viene finito di caricare il document
 */
function onDocumentLoaded() {
    // animazione menu mobile
    (0, menu_1.toggleMenuAnimation)();
    // // si inizializzano gli slider
    (0, sliders_1.initializeRecensioniSlider)();
    (0, sliders_1.initializeAboutSlider)();
    (0, sliders_1.initializeChirurgiaSlider)();
    (0, sliders_1.initializeClinicaSlider)();
    (0, sliders_1.initializeLaserTerapiaSlider)();
    (0, sliders_1.initializeMedicinaSlider)();
    // si registra il listener per lo scroll
    document.addEventListener('scroll', (ev) => {
        doOnScroll(ev);
    });
    (0, menu_1.enableThirdLevelMenu)();
}
/**
 * Chiamato allo scroll della pagina
 *
 * @param ev {@link Event}
 */
function doOnScroll(ev) {
    const oldScrollPosition = globalVariables.lastKnownScrollPosition;
    const newScrollPosition = window.scrollY;
    // per ottimizzazione si chiama changeHeaderOnScroll solo quando
    // si oltrepassa la soglia globalVariables.headerShadowTheshold definita
    if ((oldScrollPosition > globalVariables.headerShadowTheshold && newScrollPosition < globalVariables.headerShadowTheshold)
        || (oldScrollPosition <= globalVariables.headerShadowTheshold && newScrollPosition >= globalVariables.headerShadowTheshold)) {
        (0, header_1.changeHeaderProperties)(newScrollPosition >= globalVariables.headerShadowTheshold);
    }
    // NOTA: qui di seguito possono andarci altri metodi da chiamare allo scroll
    // si aggiorna lastKnownScrollPosition
    globalVariables.lastKnownScrollPosition = newScrollPosition;
}
exports.doOnScroll = doOnScroll;
